body {
  background-color: black;
}
.App {
  text-align: center;
  background-color: black;
  /* height: 2000px;  */
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* .container {
  height: 1000px;   
} */
.grid-container {
  display: grid;
  padding: 10px;
  height: 100%;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 0.025rem solid #7d13ff;
  padding: 20px;
  font-size: 30px;
  text-align: center;
  height: 5%;
}

h1 {
  color: white;
}
